.Content{
	width: 700px;
	height: 393.75px;
	border-radius: 15px;
	border: 6px #3C1521 solid;
	margin-bottom: 40px;
}
.ImgContent{
	width: 393.75px;
	height: 393.75px;
	border-radius: 15px;
	border: 6px #3C1521 solid;
	margin-bottom: 40px;
}
.inputStyle{
  border: 2px solid #5C0D10;
  border-radius: 15px;
  padding-left: 10px;
  width: 90%;
  max-width: 700px;
}

.AreaStyle{
  color: black;
	border: 2px solid #5C0D10;
  border-radius: 10px;
  padding: 5px 10px;
	width: 90%;
  max-width: 700px;
	height: 180px;
}

@media only screen and (max-width: 950px) {
	.Content {
		max-width: 700px;
		max-height: 393.75px;
		width: 90%;
		height: 90%;
		border-radius: calc(100vw * (15 / 950));
		border: calc(100vw * (6 / 950)) #3C1521 solid;
		margin-bottom: calc(100vw * (40 / 950));
	}
	.ImgContent{
		max-width: 393.75px;
		max-height: 393.75px;
		width: 90%;
		height: 90%;
		border-radius: 15px;
		border: 6px #3C1521 solid;
		margin-bottom: 40px;
	}
}