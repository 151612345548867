.UserFieldsContainer{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-bottom: 20px;
}
.UserField{
	display: flex;
	justify-content:center;
	align-items: center;
	font-size: 24px;
}
.UserField label{
	margin-right: 10px;
	text-decoration: underline;
}
.UserField input, .UserField select{
	width: 300px;
	height: 35px;
	margin-bottom: 10px;
	border-radius: 10px;
	padding: 0px 10px;
	font-size: 22px;
}

@media only screen and (max-width: 500px) {
	.UserField{
		font-size: calc(100vw * 24/500);
	}
	.UserField label{
		margin-right: calc(100vw * 10/500);
	}
	.UserField input, .UserField select{
		width: calc(100vw * 300/500);
		height: calc(100vw * 35/500);
		margin-bottom: calc(100vw * 10/500);
		border-radius: calc(100vw * 10/500);
		padding: 0px calc(100vw * 10/500);
		font-size: calc(100vw * 22/500);
	}
}