.VideoPage {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	margin: 30px auto 0px auto;
	max-width: 700px;
	padding: 0 20px 40px 20px;
}

.VideoPageTitle {
	display: flex;
	width: 100%;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 30px;
	text-align: left;
}

.VideoPageTitleOrder {
	width: 15vw;
	min-width: 20px;
	max-width: 70px;
	/* height: 70px; */
	aspect-ratio: 1;
	border-radius: 12px;
	background: #5C0D10;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 35px;
	margin-right: 20px;
}

.VideoPageTitleText {
	font-size: 32px;
}

/* .VideoPageBody {
	margin: 30px 0px;
	text-align: justify;
	font-size: 20px;
	font-weight: 300;
	width: 100%;
	max-width: 700px;
	display: flex;
	justify-content: center;
} */

.VideoPageBody div {
	width: 670px;
}

.VideoPagePlayer {
	width: min(700px, calc(100vw * 700/740));
	height: min(393.75px, calc(100vw * 393.75/740));
	border-radius: min(15px, calc(100vw * (15 / 740)));
	border: min(5px, calc(100vw * (5 / 740))) #3C1521 solid;
	margin-bottom: min(40px, calc(100vw * (40 / 740)));
	background-color: black;
}

.VideoPageAttachments {
	width: 100%;
	max-width: 700px;
	min-height: 80px;
	border-radius: 20px;
	background: linear-gradient(to right, #5D0F11, #04203E 50%);
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: flex-start;
	padding: 30px 0;
	margin-bottom: 25px;
}
.AttachmentFile{
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 90%;
}
.AttachmentFile img {
	height: 60px;
	margin-left: 20px;
}

.VideoBtnContainer {
	width: 100%;
	max-width: 680px;
	display: flex;
	justify-content: flex-end;
}

.VideoBtn {
	width: 100px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.VideoBtn img {
	width: 40px;
	cursor: pointer;
}

.BtnDisabled {
	opacity: 0.4;
}

.VideoPageBody {
	margin: 30px 0px;
	text-align: justify;
	font-size: 20px;
	font-weight: 300;
	width: 100%;
	max-width: 700px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.AttachmentFileLink{
	display: flex;
	align-items: center;
	justify-content: space-between;
	max-width: 300px;
	color: white;
	text-decoration: underline;
}
.AttachmentFileLink img{
	margin-right: 10px;
}

.AttachmentFileLink a:visited, .AttachmentFileLink a{
	color: white;
}

@media (max-width: 600px) {

	.VideoPageTitleOrder {
		font-size: 25px;
	}

	.VideoPageTitleText {
		font-size: 25px;
	}

	.VideoPageBody {
		font-size: 17px;
	}

	/* Additional styles for align-items when VideoPageTitleText has 2 or more lines */
	.VideoPageTitleText.two-lines+.VideoPageBody {
		align-items: flex-start;
	}
}
.AttNotFound{
	margin: auto auto;
}

@media (max-width: 450px) {
	.VideoPageTitle {
		align-items: flex-start;
	}
}
@media (max-width: 600px) {
	.AttachmentFile{
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-left: 20px;
		margin-bottom: 30px;
	}
}
