.AdminMenuItem{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	max-width: 300px;
	width: calc(100vw * 300/1000);
	height: calc(100vw * 400/1000);
	max-height: 400px;
	color: white;
	background: linear-gradient(to right, #5D0F11, #04203E 80%);
	margin: 0px 10px;
	border-radius: 20px;
	font-size: 32px;
}
.AdminMenuItem img{
	height: calc(100vw * 50/1000);
	margin-bottom: 10px;
	max-height: 50px;
}
.AdminMenuContainer{
	display: flex;
	align-items: center;
	justify-content: center;
}
@media only screen and (max-width: 700px) {	
	.AdminMenuItem{
		font-size: calc(100vw * 32/700);
	}
}