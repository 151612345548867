.Slider{
	margin-top: 10px;
	padding-top: 15px;
	padding-bottom: 10px;

}
.container {
	scroll-behavior: smooth;
  max-width: 840px;
  margin: 0 auto;
}
.heading{
	margin-bottom: 20px;
}
.ActiveSlideTitle{
  font-size: 28px;
  width: 90%;
  max-width: 500px;
  margin: 0px auto 30px auto;
  font-weight: 200;
}
.swiper_container {
  height: 360px;
  position: relative;
  width: 100%;
  /* background-color: #000; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-slide {
	width: 300px;
  height: 300px;
  position: relative;
	border-radius: 15px;
	filter: blur(2px);
}
.swiper-slide-active{
	filter: blur(0px);
}
.swiper-slide img {
  width: 300px;
  height: 300px;
  border-radius: 8px;
  object-fit: cover;
}
.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
  display: none;
}
.NewsBlurCircle {
  position: absolute;
  width: 40vw;
  height: 40vw;
  z-index: -1;
  filter: blur(40px);
  margin-top: -65px;
}
.NewsCircleRight{
  border-radius: 100% 0px 0px 100%;
	right: 0px;
  background: linear-gradient(to bottom right, #5D0F11, #04203E 50%);;
}
.NewsCircleLeft{
  border-radius: 0px 100% 100% 0px;
	left: 0px;
  background: linear-gradient(to bottom left, #5D0F11, #04203E 50%);;
}
/* .slider-controler {
  bottom: 2rem;
	height: 20px;
	margin: 0px auto;
} */

.slider-arrow > img{
  width: 40px;
  border: solid #680005 2px;
  border-radius: 100%;
}

.slider-controler .slider-arrow::after {
  content: '';
}
.swiper-pagination .swiper-pagination-bullet {
 background: #999999;
}
.swiper-pagination .swiper-pagination-bullet-active {
  background:white;
}



.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); 
  background-color: #010F1E;
  padding: 20px;
  border-radius: 5px;
  z-index: 999; 
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  width: 85%;
  max-width: 800px;
}
.popup img {
  max-width: 100%; 
  height: auto;
}
.popup button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
}
.popup button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #010F1E; /* Transparent background with a hint of white */
  border: 1px solid white; /* White border for contrast */
  color: white;
  padding: 8px 12px; /* Add padding for better appearance */
  border-radius: 3px;
  cursor: pointer;
  background: #000;
}

@media (max-width: 425px) {
  .heading {
    margin-bottom: 0
  }
  .ActiveSlideTitle {
    font-size: 20px;
  }
}