.LevelPage {
	padding: 0 15px;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	width: 90%;
	max-width: 700px;
	margin: 30px auto 60px auto;
}

.LevelVideos {
	background: linear-gradient(to bottom left, #5D0F11, #04203E 40%);
	width: 100%;
	max-width: 570px;
	min-height: 230px;
	padding-top: 20px;
	padding-bottom: 10px;
	border-radius: 20px;
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.VideoListTitleContainer {
	display: flex;
	align-items: flex-start;
	width: 95%;
	margin-bottom: 20px;
	margin-left: 5%;
}

.VideoListTitle {
	width: 100%;
	text-align: left;
	font-size: 18px;
	cursor: pointer;
	color: white;
	margin-bottom: 8px;
}

.VideoListCircle {
	width: 40px;
	height: 40px;
	aspect-ratio: 1;
	background: #5D0F11;
	border-radius: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 18px;
	cursor: pointer;
	color: white;
	margin-right: 20px;
}

.WatchVideo {
	width: 110px;
	height: 24px;
	background: white;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #870003;
	font-size: 12px;
	font-weight: 700;
	border-radius: 7px;
	cursor: pointer;
}

.WatchVideo>img {
	height: 12px;
	margin-left: 8px;
}