.Header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 85%;
	margin: 0px auto;
	font-size: 18px;
	margin-top: 13px;
	cursor: pointer;
}

.HeaderLogo {
	height: 70px;
}

.HeaderMenu {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 475px;
}

.QuickLinks {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 312px;
}

.QuickLinks div{
	cursor: pointer;
}

.HeaderBtn {
	width: 118px;
	height: 40px;
	border-radius: 7px;
	font-size: 18px;
	cursor: pointer;
	font-weight: 600;
	color: #3C1521;

	border-color: #3C1521;
	border-style: solid;
}

.QuickLinksIcon {
	display: none;
	cursor: pointer;
}

.HeaderMenuItems {
  width: 100vw;
  background-color: #3C1521;
  position: fixed;
  top: 0;
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 10px;
  transform: translateY(-100%);
  transition: transform 0.3s ease-in-out;
}

.HeaderMenuItems.open {
  transform: translateY(0);
}

.HeaderMenuItem {
	height: 25px;
	margin: 10px 0;
	display: flex;
	align-items: center;
	width: 90%;
	font-size: 18px;
	padding-bottom: 5px;
	border-bottom: 1px solid white;
	cursor: pointer;
}

.CloseHeaderMenuItems img{
	height: 25px;
	width: calc(100% - 40px);
	object-fit: contain;
	object-position: right;
	cursor: pointer;
	margin: 7px;
}

.CloseHeaderMenuItems {
	height: 40px;
	width: calc(100% - 40px);
	margin: 20px 0 0 20px;
	object-fit: contain;
	object-position: right;
}

.CloseHeaderMenuItems:active {
	opacity: 0.7;
}

.DarkHeaderMenuBG {
	background-color: black;
	width: 100vw;
	height: 100vh;
	position: fixed;
	z-index: 2;
	opacity: 0.7;
}

body.menu-open {
	overflow: hidden;
}

@media only screen and (max-width: 950px) {
	.Header {
		font-size: calc(100vw * (18 / 950));
		;
		margin-top: calc(100vw * (13 / 950));
	}

	.HeaderLogo {
		height: calc(100vw * (70 / 950));
	}

	.HeaderMenu {
		width: calc(100vw * (475 / 950));
	}

	.QuickLinks {
		width: calc(100vw * (312 / 950));
	}

	.HeaderBtn {
		width: calc(100vw * (118 / 950));
		height: calc(100vw * (40 / 950));
		border-radius: calc(100vw * (7 / 950));
		font-size: calc(100vw * (18 / 950));
	}
}

@media only screen and (max-width: 850px) {
	.Header {
		font-size: 16px;
		margin-top: 12px;
		width: 90%;
	}

	.HeaderLogo {
		height: 58px
	}

	.HeaderMenu {
		width: 170px;
		flex-direction: row-reverse;
	}

	.QuickLinks {
		display: none;
	}

	.HeaderBtn {
		width: 105px;
		height: 36px;
		border-radius: 5px;
		font-size: 16px;
	}

	.QuickLinksIcon {
		display: block;
		height: 28px;
	}
}

@media only screen and (max-width: 350px) {
	.Header {
		font-size: calc(100vw * (16 / 350));
		margin-top: calc(100vw * (12 / 350));
		width: 90%;
	}

	.HeaderLogo {
		height: calc(100vw * (58 / 350));
	}

	.HeaderMenu {
		width: calc(100vw * (170 / 350));
		flex-direction: row-reverse;
	}

	.HeaderBtn {
		width: calc(100vw * (105 / 350));
		height: calc(100vw * (36 / 350));
		border-radius: calc(100vw * (5 / 350));
		font-size: calc(100vw * (16 / 350));
	}

	.QuickLinksIcon {
		display: block;
		height: calc(100vw * (28 / 350));
	}
}