.SearchContainer{
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 30px;
}
.SearchContainer a{
	display: flex;
	align-items: center;
	justify-content: center;
}

.ListSearch{
	width: 400px;
	height: 35px;
	font-size: 24px;
	padding: 5px 10px;
	border-radius: 10px;
}
.AddBtn{
	width: 50px;
	margin-left: 10px;
}
.UsersListContainer a{
	color: white;
}
.UsersListContainer{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.UsersListItem{
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 95%;
	max-width: 600px;
	padding: 0px 5px 10px 5px;
	border-bottom: 1px white solid;
	margin-bottom: 20px;
	font-size: 20px;
}
@media only screen and (max-width: 510px) {
	.ListSearch{
		width: calc(100vw * 400/510);
		height: calc(100vw * 35/510);
		font-size: 24px;
		padding: 5px 10px;
		border-radius: 10px;
		font-size: 20px;
	}
	.AddBtn{
		width: calc(100vw * 50/510);
	}
	.UsersListItem{
		flex-direction: column;
		width: 85%;
		font-size: calc(100vw *20/510)
	}
}