.Footer{
	padding-bottom: 30px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 0px auto;
	margin-top: 50px;

}
.FooterTop{
	background: linear-gradient(to top,#241112 0%, #1A1015 30%, #010F1E 100%);
}
.FooterBottom{
	background: linear-gradient(to bottom,#241112 0%, #1A1015 30%, #010F1E 100%);
}
.Footer img{
	width: 70px;
	margin-bottom: 20px;
}
.Footer div{
	width: 280px;
	font-size: 16px;
	text-align: center;
}