.AuthInput{
	height: 50px;
	width: 85%;
	max-width: 400px;
	padding: 0px 10px;
	border: 2px solid #5C0D10;
  border-radius: 15px;
	font-size: 22px;
}

.AuthTitle{
	font-size: 32px;
	font-weight: 500;
	margin: 22px 0px;
}

.AuthContainer{
	width: 90%;
	max-width: 600px;
	height: 300px;
	border-radius: 20px;
	margin: 0px auto 40px auto;
	background: linear-gradient(to right, #5D0F11, #04203E 80%);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

@media only screen and (max-width: 450px) {	
	.AuthInput{
		height: 35px;
		font-size: 17px;
	}
	.AuthTitle{
		font-size: 26px;
		font-weight: 500;
		margin: 15px 0px;
	}
}