.CourseBlurCircle {
	border-radius: 100%;
	background: linear-gradient();
	position: absolute;
	width: 700px;
	height: 700px;
	z-index: -1;
	background: linear-gradient(to bottom right, #5D0F11, #04203E 50%);
	filter: blur(60px);
	margin-top: 150px;
	margin-right: -340px;
	right: 0px;
}

.CourseLevels {
	padding-top: 25px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-left: 15px;
	padding-right: 15px;
}

.CourseTitle {
	font-size: 32px;
	font-weight: 700;
	margin-bottom: 50px;
}

.CourseLevelContainer {
	margin: 0px auto;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
	max-width: 700px;
	position: relative;
}

.LevelNumberContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 15%;
	max-width: 80px;
}

.NumberSquare {
	width: 100%;
	/* height: 80px; */
	aspect-ratio: 1;
	border-radius: 12px;
	background: #5C0D10;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 35px;
}

.NumberLine {
	width: 12%;
	max-width: 5px;
	background: #5C0D10;
}

.NumberLineCircle {
	width: 20px;
	height: 20px;
	background: #5C0D10;
	border-radius: 100%;
}

.CourseLevelContent {
	display: flex;
	flex-direction: column;
	justify-content: left;
	align-items: flex-start;
	max-width: 556px;
	width: 80%;
	cursor: pointer;
}

.CourseLevelTitle {
	font-size: 26px;
	color: #ffffff;
	font-weight: 400;
	margin-bottom: 10px;
	cursor: pointer;
	text-align: left;
}

.CourseLevelTitleOff {
	font-size: 26px;
	color: #ffffff;
	font-weight: 400;
	margin-bottom: 10px;
	text-align: left;
}

.CourseLevelBody {
	font-size: 17px;
	font-weight: 400;
	color: #d4d6d6;
	margin-bottom: 20px;
	text-align: justify;
}

.CourseLevelThumbnail {
	width: 100%;
	height: 100%;
	cursor: pointer;
	object-fit: cover;
	border-radius: 10px;
}

.CourseLevelThumbnailOff {
	width: 100%;
	height: 100%;
	border-radius: 10px;
	object-fit: cover;
}

.ThumbnailLockContainer {
	width: 100%;
	max-width: 560px;
	height: 100%;
	border-radius: 15px;
	margin-bottom: 60px;
	border: 5px #3C1521 solid;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ThumbnailLockContainerOff {
	width: 100%;
	height: 100%;
	border-radius: 15px;
	border: 5px #3C1521 solid;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
}

.CourseLevelLocked {
	opacity: 0.6;
}

.CourseLevelLocked * {
	cursor: not-allowed;
}

.ThumbnailLock {
	position: absolute;
	width: 150px;
}

@media (max-width: 425px) {
	.CourseTitle {
		margin-bottom: 20px
	}

	.CourseLevelContainer {
		width: 95%;
	}

	.LevelNumberContainer {
		max-width: 45px;
	}

	.NumberSquare {
		font-size: 25px;
	}

	.CourseLevelTitleOff {
		font-size: 20px;
	}

	.CourseLevelBody {
		font-size: 15px;
	}
}

.CourseBtn{
	margin-top: -25px;
}