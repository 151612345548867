.Welcome {
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.WelcomeTitle {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 40px;
}

.WelcomeTitle>div {
	/* width: 400px;
	font-size: 55px; */
	font-size: 50px;
	line-height: 55px;
}

.WelcomeLine {
	width: 330px;
	margin-left: 50px;
}

.WelcomeTitle>p {
	font-size: 18px;
	color: #a5abab;
	line-height: 26px;
	width: 700px;
	margin-top: 10px;
}

.WelcomeBtns {
	width: 400px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #3C1521;
}

.WelcomeBtns button {
	width: 200px;
	height: 50px;
	margin: 0px 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 22px;
	border-radius: 12px;
	font-weight: 600;
	color: #3C1521;
	border-style: solid;
	border-color: #3C1521;
	background: white;
}

.GetStarted {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 200px;
}

.GetStarted>img {
	margin-left: 8px;
	width: 20px;
}

.WelcomeNumbers {
	width: 670px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.WelcomeNumbersItem {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.Number {
	margin-bottom: 5px;
	font-size: 50px;
}

.NumberFooter {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	font-size: 19px;
}

.NumberFooter div {
	margin-left: 5px;
}

.NumberFooter img {
	width: 39px;
}

.WelcomeVideo {
	width: min(700px, calc(100vw * 700/950));
	height: min(393.75px, calc(100vw * 393.75/950));
	border-radius: min(15px, calc(100vw * (15 / 950)));
	border: min(6px, calc(100vw * (6 / 950))) #3C1521 solid;
	margin-bottom: min(40px, calc(100vw * (40 / 950)));
}

@media only screen and (max-width: 950px) {

	.Welcome {
		/* margin-top: calc(100vw * (20 / 950)); */
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.WelcomeTitle {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: calc(100vw * (40 / 950));
		width: 90%;
		max-width: 670px
	}

	.WelcomeTitle>div {
		font-size: 45px;
	}

	.WelcomeLine {
		width: 240px;
		margin-left: 15px;
	}

	.WelcomeTitle>p {
		font-size: 18px;
		line-height: 23px;
		width: 100%;
		margin-top: 0px;
	}

	.WelcomeBtns {
		width: 85vw;
		height: 17vw;
		max-width: 400px;
		max-height: 50px;
	}

	.WelcomeBtns button {
		width: 40.8vw;
		max-width: 200px;
		height: 10.2vw;
		max-height: 50px;
		font-size: 19px;
	}

	.GetStarted>img {
		margin-left: 5px;
		width: 18px;
	}

	.WelcomeNumbers {
		width: calc(100vw * (670 / 950));
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.Number {
		margin-bottom: calc(100vw * (5 / 950));
		font-size: calc(100vw * (50 / 950));
	}

	.NumberFooter {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		font-size: calc(100vw * (19 / 950));
	}

	.NumberFooter div {
		margin-left: calc(100vw * (5 / 950));
	}

	.NumberFooter img {
		width: calc(100vw * (39 / 950));
	}
}

@media only screen and (max-width: 450px) {
	.WelcomeBtns button {
		width: 40.8vw;
		max-width: 200px;
		height: 10.2vw;
		max-height: 50px;
		font-size: 4.5vw;
	}

	.GetStarted>img {
		margin-left: calc(100vw * (5 / 380));
		width: calc(100vw * (15 / 380));
	}

	.WelcomeNumbers {
		flex-direction: column;
		width: 200px;
	}

	.WelcomeNumbersItem {
		margin: 10px;
	}
	
	.Number {
		margin-bottom: 0;
		font-size: 50px;
	}

	.NumberFooter {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		font-size: 15px;
		text-align: left;
		margin-top: -10px;
		width: 100%;
	}
}